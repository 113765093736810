
import useLocale from '../useLocale';

export interface StartDateProps {
  date: string;
}

/**
 * @param date UTC date time.
 */
const StartDate = ({ date }: StartDateProps) => {
  const { formatDate, parseDate } = useLocale();

  const utcDate = parseDate(date, { timezone: 'UTC' });
  const projectDate = new Date(formatDate(utcDate, { format: 'internal_date_time' }));
  const format = projectDate.getHours() + projectDate.getMinutes() + projectDate.getSeconds() > 0
    ? 'display_time_slot_date_time' : 'display_time_slot_date';

  return (
    <>
      {formatDate(projectDate, { format, timezone: null })}
    </>
  );
};

export default StartDate;
